<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Sucursales"
      subtitle="| Administrador de Sucursales"
      class="heading-block"
    >
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
          <b-button
            variant="alt-primary"
            class=""
            href="javascript:void(0)"
            v-click-ripple
            v-b-popover.hover.bottom="'Crear una sucursal'"
            @click="ShowModalSucursal()"
            style="width: 200px"
          >
            <i class="fa fa-plus-square mr-1"></i> Nueva sucursal
          </b-button>
          <!-- Small Block Modal -->
          <b-modal
            id="modal-sucursal"
            size="md"
            body-class="p-0"
            hide-footer
            hide-header
          >
            <div
              class="block block-rounded block-themed block-transparent mb-0"
            >
              <div class="block-header bg-primary-dark">
                <h3 class="block-title">
                  <i class="fa fa-user mr-1"></i> Alta de sucursal
                </h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    @click="$bvModal.hide('modal-sucursal')"
                  >
                    <i class="fa fa-fw fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-row class="mt-1">
                    <b-col sm="12">
                      <b-form-group label-for="almacen-input">
                        <template #label>
                          Nombre <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="almacen-input"
                          name="almacen-input"
                          placeholder="Ingresa nombre de la sucursal..."
                          v-model="form.name"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <br />
                </form>
              </div>
              <div
                class="block-content block-content-full text-right border-top"
              >
                <b-button
                  variant="alt-primary"
                  class="mr-1"
                  @click="$bvModal.hide('modal-sucursal')"
                  >Cerrar</b-button
                >
               
                <template v-if="edit == false">
                  <b-button variant="primary" @click="CreateSucursal()"
                    >Guardar</b-button
                  >
                </template>
                <template v-else> 
                  <b-button variant="danger" @click="UpdateSucursal()"
                    >Actualizar</b-button
                  >
                </template>

              </div>
            </div>
          </b-modal>
          <!-- END Small Block Modal -->
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Busqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table :items="sucursales" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  <template #cell(actions)="row">
                      <b-button size="sm" @click="EditSucursal(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteSucursal(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
              </b-table>
            </div>
        </b-overlay>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import Sucursal from "../../../api/admin/ubicacion/Sucursales";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sucursales: [],
      filter: null,
      timeout: null,
      edit : false,
      showoverlay:false,
      fields: [
        {key: 'id',sortable: true, label: '# ID'},
        {key: 'name',sortable: true, label: 'NOMBRE'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      form: {
        id:"",    
        name: "",
      },
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.sucursales.length
    }
  },
  methods: {

    AllSucursales() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      
      Sucursal.GetAllSucursales(auth)
        .then((response) => {
          this.sucursales = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateSucursal() {
      this.$bvModal.hide("modal-sucursal");
      if (this.form.name ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          Sucursal.PostCreateSucursal(this.form, auth)
            .then((response) => {              
              this.AllSucursales();
              this.showoverlay = false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                id:"",    
                name: "",
              };
              
            })
            .catch((error) => {
              Swal.fire({
                title: "Que mal!",
                text: error.message,
                icon: "error",
              });
            });
      }else{
        this.errors = []
        if (!this.form.name) {
          let me=this
          me.errors.push('El campo nombre es requerido')
        }          
        Swal.fire({
          title: "Corrija los siguientes errores",
          icon: "error",
          html: this.errors
              .map(item => `<p> <li>${item}</li></p>`)
              .join('')
        });
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalSucursal() {
      this.form = {
          id:"",    
          name: "",
      };
      this.$bvModal.show("modal-sucursal");
      this.edit=false;
    },

    EditSucursal(data) {
      this.$bvModal.show("modal-sucursal");
      this.edit = true;
      this.form = {
        id: data.id,
        name : data.name,
      };
    },

    UpdateSucursal(){
      this.$bvModal.hide("modal-sucursal");
        this.showoverlay = true;
        let auth = JSON.parse(localStorage.autentication);
        Sucursal.UpdateSucursal(this.form, auth)
        .then((response) => {
          this.AllSucursales();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.form = {
              id:"",    
              name: "",
          };          
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    DeleteSucursal(id){
      Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                this.showoverlay = true;
                let auth = JSON.parse(localStorage.autentication);
                console.log(id)
                Sucursal.DeleteSucursal(id, auth)
                .then((response) => {
                  this.AllSucursales();
                  this.showoverlay = false;
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });                  
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                });
            } else {
                // Dijeron que no
                console.log("*NO se elimina la venta*");
            }
        });
        
    },


  },

  mounted() {
    let me = this;
    me.AllSucursales();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
